import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import nwAnalytics from '@nerdwallet/analytics';
import {
  Impression,
  ImpressionContext,
  // @ts-ignore
} from '@nerdwallet/analytics/react/impression';
import { Box, Text } from '@nerdwallet/currency';
import CloseIcon from '@nerdwallet/react-icon/nerdwallet-ui/Close';

import classNames from 'classnames';
import classes from '@nerdwallet/base-styles/classes';
import SegmentImpression from '../../segment-impression';
import NwPlusTag from './nw-plus-tag/nw-plus-tag';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import styles from './announcement-banner.module.scss';

interface Props {
  handleOnClose: () => void;
  text: string;
  textMobile: string;
  ctaText: string;
  ctaTextMobile: string;
  ctaLink: string;
  campaign: string;
  campaignKey: string;
}

type ActionType = 'close' | 'select';

const AnnouncementBanner: React.FC<Props> = ({
  handleOnClose = () => {},
  text,
  textMobile,
  ctaText,
  ctaTextMobile,
  ctaLink,
  campaign,
  campaignKey,
}) => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const [isDesktop, setIsDesktop] = useState<boolean>();
  const [segmentImpressionId] = useState(uuidv4());

  useEffect(() => {
    const userAgent = navigator?.userAgent;

    // No link for mobile
    setIsDesktop(userAgent?.indexOf('Mobi') === -1);
  }, []);

  const handleClickTracking =
    (type: ActionType, impressionId: string, cb = () => {}) =>
    () => {
      const action = type === 'close' ? 'closed' : 'clicked';

      trackSegmentTrackEvent(`${campaign} Banner CTA ${action}`, {
        parent_impression_id: segmentImpressionId,
        section_name: 'desktop_homepage',
      });
      nwAnalytics.track('element_interacted', {
        action: type,
        entity_name: `${campaignKey}_banner_cta_${action}`,
        impression_id: impressionId,
        interaction_type: 'press',
      });

      if (cb) cb();
    };

  return (
    <SegmentImpression
      eventName={`${campaign} Banner Viewed`}
      eventProps={{
        impression_id: segmentImpressionId,
        section_name: isDesktop ? 'desktop_homepage' : 'mobile_web_homepage',
      }}
    >
      <Impression
        eventType="element_impression"
        entityName={`${campaignKey}_announcement_banner`}
        payload={{
          section_name: isDesktop ? 'desktop_homepage' : 'mobile_web_homepage',
        }}
      >
        <ImpressionContext.Consumer>
          {({ impressionId }: { impressionId: any }) => (
            <Box
              className={styles.bannerContainer}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection={{ desktop: 'row', mobile: 'column' }}
                className={styles.bannerContent}
              >
                <NwPlusTag
                  classNames={classNames([
                    styles.tag,
                    styles.tagMobile,
                    classes('color-white'),
                  ])}
                />
                <Box
                  display="flex"
                  flexDirection={{
                    desktop: 'row',
                    mobile: 'column',
                  }}
                  marginLeft={{ desktop: 2, mobile: 0 }}
                  marginTop={{ desktop: 0, mobile: 1 }}
                >
                  <Box
                    display={{ desktop: 'block', mobile: 'none' }}
                    marginRight={{ desktop: 2, mobile: 0 }}
                  >
                    <div className={styles.text}>
                      <Text>{text}</Text>
                    </div>
                  </Box>
                  <Box
                    display={{ desktop: 'none', mobile: 'block' }}
                    marginRight={{ desktop: 2, mobile: 0 }}
                  >
                    <div className={styles.text}>
                      <Text>{textMobile}</Text>
                    </div>
                  </Box>
                  <Box
                    display={{ desktop: 'block', mobile: 'none' }}
                    marginTop={{ desktop: 0, mobile: 1 }}
                  >
                    <a
                      className={styles.link}
                      color="green-darkest"
                      href={`${ctaLink}?trk=announcement_banner`}
                      onClick={handleClickTracking('select', impressionId)}
                    >
                      {ctaText}
                    </a>
                  </Box>
                  <Box
                    display={{ desktop: 'none', mobile: 'block' }}
                    marginTop={{ desktop: 0, mobile: 1 }}
                  >
                    <a
                      className={styles.link}
                      color="green-darkest"
                      href={`${ctaLink}?trk=announcement_banner`}
                      onClick={handleClickTracking('select', impressionId)}
                    >
                      {ctaTextMobile}
                    </a>
                  </Box>
                </Box>
              </Box>
              <Box display={{ desktop: 'flex', mobile: 'none' }}>
                <CloseIcon
                  className={styles.closeIcon}
                  color="#8B8C8F"
                  onClick={handleClickTracking(
                    'close',
                    impressionId,
                    handleOnClose,
                  )}
                  size="medium"
                />
              </Box>
            </Box>
          )}
        </ImpressionContext.Consumer>
      </Impression>
    </SegmentImpression>
  );
};

export default AnnouncementBanner;
