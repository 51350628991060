import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export interface CampaignHighlight {
  title: string;
  subtitle: string;
  titleEnd: string;
  backgroundColor: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  titlePreColor: string;
  titleEndColor: string;
  visualBackgroundImageDesktopUrl: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageAlt: string;
  nwplusFullLogo: string;
  nwplusFullLogoAlt: string;
  nwplusSmallLogo: string;
  trkQueryParam: string;
}

const content: CampaignHighlight = {
  title: "You could save hundreds a year by switching your insurance",
  subtitle: "Our insurance assistant tool shops popular carriers to get you quotes — you could save hundreds with just a few clicks. Join NerdWallet+ to get access.",
  titleEnd: '',
  backgroundColor: '#E8FDAC',
  ctaButtonText: 'Learn More',
  ctaLinkUrl: 'lp/nerdwallet-plus-main',
  titlePreColor: '#000000',
  titleEndColor: '#e2ff39',
  visualBackgroundImageDesktopUrl: `/nwplus_campaign_insurance_wide.png`,
  visualBackgroundImageMobileUrl: `/nwplus_campaign_insurance_mobile.png`,
  visualBackgroundImageAlt: 'NerdWallet+ phone app showing better insurance quote',
  nwplusFullLogo: `/nwplus_full_logo.png`,
  nwplusFullLogoAlt: 'NerdWallet Plus Logo',
  nwplusSmallLogo: `/nwplus_small_logo.png`,
  trkQueryParam: analyticsBase.entity_names.campaign, // used to append to the URL when the button is clicked,
};

export default content;
