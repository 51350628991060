import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from '@nerdwallet/base-styles/classes';
import { Text, Link } from '@nerdwallet/currency';
import { Impression } from '@nerdwallet/analytics/react/impression';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import styles from './cc-best.module.scss';

const CCBest = ({ eyebrow, heading, links }) => {
  const { trackSegmentTrackEvent } = useAnalytics();
  const handleLinkClick = (label) => {
    const entityName = `hp_bestcards_${label.toLowerCase().replace(' ', '')}`;
    trackSegmentTrackEvent('Element Interaction', {
      entity_name: entityName,
      location: HOMEPAGE_SEGMENT_INSTRUMENTATION.cc_best.location,
      sub_location: HOMEPAGE_SEGMENT_INSTRUMENTATION.cc_best.sub_location,
      action: 'select',
    });
  };
  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.cc_best.entity_name,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.cc_best.location,
      }}
    >
      <Impression
        entityName={analyticsBase.entity_names.cc_best}
        payload={{ section_name: analyticsBase.section_name }}
      >
        <div className={styles['cc-best__wrapper']}>
          <div
            className={classNames([
              styles['cc-best__content'],
              classes('border-neutral-lighter'),
              classes('border-all-1'),
              classes('padding-vertical-4'),
              classes('padding-horizontal-4'),
              classes('display-flex'),
              classes('flex-direction-column'),
              classes('flex-direction-row--wide'),
            ])}
          >
            <div
              className={classNames([
                styles['cc-best-section__text'],
                classes('margin-horizontal-auto'),
                classes('text-align-center'),
                classes('width-100'),
              ])}
            >
              <div
                className={classNames([
                  styles['cc-best-section__eyebrow'],
                  classes('margin-bottom-3'),
                ])}
              >
                <Text size="small">{eyebrow}</Text>
              </div>
              <div
                className={classNames([
                  styles['cc-best-section__heading'],
                  classes('margin-bottom-4'),
                ])}
              >
                <Text size="large" bold component="h2">
                  {heading}
                </Text>
              </div>
              <Fragment>
                {links.map((link) => (
                  <div className={styles['cc-best__link']} key={link.label}>
                    <span className={styles['cc-best__link-emoji']}>
                      {String.fromCodePoint(...link.emojiCodePoint)}
                    </span>
                    <Link
                      href={link.href}
                      onClick={() => handleLinkClick(link.label)}
                    >
                      {link.label}
                    </Link>
                  </div>
                ))}
              </Fragment>
            </div>
          </div>
        </div>
      </Impression>
    </SegmentImpression>
  );
};

CCBest.propTypes = {
  eyebrow: PropTypes.string,
  heading: PropTypes.string,
  links: PropTypes.object,
};

CCBest.displayName = 'CCBest';

export default CCBest;
