import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Impression } from '@nerdwallet/analytics/react/impression';
import { Button, Text, Link, Divider } from '@nerdwallet/currency';
import Image from '@nerdwallet/react-image';
import nwAnalytics from '@nerdwallet/analytics';
import styles from './secondary-campaign-module.module.scss';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';

const SecondaryCampaignModule = ({
  backgroundColor,
  backgroundColorMobile,
  titleImage,
  subtitle,
  ctaButtonText,
  ctaLinkUrl,
  visualBackgroundImageUrl,
  visualBackgroundImageMobileUrl,
  elementImpressionEntityName,
  elementImpressionSectionName,
  instrumentationKey,
  trkQueryParam,
}) => {
  const backgroundImageStyleDesktop = {
    backgroundColor: `${backgroundColor}`,
    backgroundImage: `url(${visualBackgroundImageUrl})`,
    backgroundSize: `cover`,
    backgroundPosition: 'center',
  };

  const backgroundImageStyleMobile = {
    backgroundColor: `${backgroundColorMobile}`,
    backgroundImage: `url(${visualBackgroundImageMobileUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 233,
    width: '100%',
  };

  const analyticsObj =
    HOMEPAGE_SEGMENT_INSTRUMENTATION.secondary_campaign_module[
      instrumentationKey
    ];

  const { trackSegmentTrackEvent } = useAnalytics();
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent('Element Interaction', analyticsObj);
    nwAnalytics.track('element_interacted', {
      action: 'select',
      entity_name: analyticsObj.entityName,
      impression_id: uuidv4(),
      interaction_type: 'press',
    });
  };

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: analyticsObj.sub_location,
        location: analyticsObj.location,
      }}
    >
      <Impression
        entityName={elementImpressionEntityName}
        payload={{ section_name: elementImpressionSectionName }}
      >
        <div className={styles.campaignHighlight}>
          <Link
            href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
            onClick={handleCampaignLinkClick}
            className={styles.campaignHighlightVisualContainerMobile}
          >
            <div style={backgroundImageStyleMobile} />
          </Link>
          <div
            className={styles.campaignHighlightCta}
            style={{ backgroundColor }}
          >
            <Image
              imageClassName={styles.titleImage}
              backgroundColor="none"
              src={titleImage}
            />
            <div className={styles.campaignHighlightCtaText}>
              <Text>{subtitle}</Text>
            </div>
            <Button
              primary
              href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
              className={styles.campaignHighlightCtaButton}
              onClick={() => handleCampaignLinkClick()}
            >
              {ctaButtonText}
            </Button>
          </div>
          <Link href={`${ctaLinkUrl}?trk=${trkQueryParam}`}>
            <div
              className={styles.campaignHighlightVisualContainerDesktop}
              style={backgroundImageStyleDesktop}
            />
          </Link>
        </div>
        <Divider className={styles.divider} />
      </Impression>
    </SegmentImpression>
  );
};

SecondaryCampaignModule.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundColorMobile: PropTypes.string,
  titleImage: PropTypes.string,
  subtitle: PropTypes.string,
  ctaButtonText: PropTypes.string,
  ctaLinkUrl: PropTypes.string,
  visualBackgroundImageUrl: PropTypes.string,
  visualBackgroundImageMobileUrl: PropTypes.string,
  elementImpressionEntityName: PropTypes.string,
  elementImpressionSectionName: PropTypes.string,
  instrumentationKey: PropTypes.string,
  trkQueryParam: PropTypes.string,
};

SecondaryCampaignModule.displayName = 'SecondaryCampaignModule';

export default SecondaryCampaignModule;
