'use client';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@nerdwallet/currency';
import styles from './type-module.module.scss';

const TypeModule = ({
  prefix = '',
  inline = false,
  phraseLibrary,
  speed = 150,
}) => {
  const [text, setText] = useState(phraseLibrary[0]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [textSpeed, setTextSpeed] = useState(speed);
  const [loop, setLoop] = useState(0);
  const i = loop % phraseLibrary.length;
  const phrase = phraseLibrary[i];

  const textStyle = {
    display: inline ? 'inline' : 'block',
  };
  const getText = () => {
    return inline ? ` ${text}` : text;
  };
  const typePhrase = () => {
    // type out phrase char by char or delete phrase char by char
    setText(
      isDeleting
        ? phrase.substring(0, text.length - 1)
        : phrase.substring(0, text.length + 1),
    );
    // set speeds for deleting phrase v. typing phrase to mimic analog typing behavior better
    setTextSpeed(isDeleting ? 80 : speed);

    // once entire phrase has been typed out, pause before deleting - similarly, if entire phrase has been deleted, pause before looping to next phrase in the phraseLibrary
    if (!isDeleting && text === phrase) {
      setTimeout(() => setIsDeleting(true), 800);
    } else if (isDeleting && text === '') {
      setTimeout(() => {
        setIsDeleting(false);
        setLoop(loop + 1);
      }, 500);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      typePhrase();
    }, textSpeed);
    return () => clearTimeout(timer);
  });

  return (
    <React.Fragment>
      <Title component="h1">
        {prefix}
        <span className={styles.text} aria-label={phrase} style={textStyle}>
          {getText()}
          <span className={styles.cursor} />
        </span>
      </Title>
    </React.Fragment>
  );
};

TypeModule.propTypes = {
  phraseLibrary: PropTypes.arrayOf(PropTypes.shape(TypeModule.propTypes)),
  speed: PropTypes.number,
  prefix: PropTypes.string,
  inline: PropTypes.bool,
};

export default TypeModule;
