import appConfig from '@nerdwallet/app-config';
import { HOMEPAGE as analyticsBase } from '../analytics/analytics-constants';

export interface Badge {
  url: string;
  height: number;
  width: number;
  top: boolean;
  alt: string;
}

export interface SingleLayoutProps {
  titleImage: string;
  visualBackgroundImageMobileUrl: string;
  visualBackgroundImageUrl: string;
  backgroundColor: string;
  backgroundColorMobile: string;
}

export interface CampaignCardProps {
  badge: Badge;
  subtitle: string;
  ctaButtonText: string;
  ctaLinkUrl: string;
  elementImpressionEntityName: string;
  elementImpressionSectionName: string;
  instrumentationKey: string;
  trkQueryParam: string;
  singleLayout?: SingleLayoutProps;
}

const cards: CampaignCardProps[] = [
  {
    badge: {
      url: `${appConfig.ASSETS_URL}/images/homepage/nw_advisors_badge.png`,
      height: 17,
      width: 211,
      top: false,
      alt: 'NW ADVISORS',
    },
    subtitle:
      'Need more guidance? Get unlimited 1:1 advice from a\nCertified Financial Planner™.',
    ctaButtonText: 'GET STARTED',
    ctaLinkUrl: 'http://nerdwalletadvisors.com',
    elementImpressionEntityName:
      analyticsBase.entity_names.secondary_campaign_module, // used for the Impression of the component
    elementImpressionSectionName: analyticsBase.section_name,
    instrumentationKey: 'nw_advisors',
    trkQueryParam: analyticsBase.entity_names.secondary_campaign_module,
    singleLayout: {
      titleImage: `${appConfig.ASSETS_URL}/images/homepage/nw_advisors.png`,
      visualBackgroundImageMobileUrl: `${appConfig.ASSETS_URL}/images/homepage/nw_advisors_mobile.png`,
      visualBackgroundImageUrl: `${appConfig.ASSETS_URL}/images/homepage/homepage-nw-advisor-1280.jpg`,
      backgroundColor: '#EEF7FF',
      backgroundColorMobile: '#DEEFFF',
    },
  },
];

export default cards;
