'use client';
/**
 * Dependencies
 */
import React, { useState } from 'react';

import { NerdAIChat } from '@nerdwallet/ai-components';
import { Box } from '@nerdwallet/currency/components/Box';
import {
  Experiment,
  ExperimentControl,
  ExperimentVariant,
} from '@nerdwallet/features';
import { AnalyticsContextProvider } from '@nerdwallet/structured-content/contexts/AnalyticsContext';
import StructuredContent, {
  StructuredContentProvider,
  createClient,
} from '@nerdwallet/structured-content-core';
import dynamic from 'next/dynamic';
import schema from '@nerdwallet/structured-content/schema';

// Local Components
import VerticalComparison from '~/app/components/front-page/vertical-comparison/vertical-comparison';
import VerticalComparisonNoTyping from '~/app/components/front-page/vertical-comparison/vertical-comparison-no-typing';
import CCBest from '~/app/components/front-page/cc-best/cc-best';
import NewsArticles from '~/app/components/front-page/news-articles/news-articles-container/news-articles-container';
import { EXPERIMENTS } from '~/app/experiments';
import HomepageRemoveBannerMobile from '../experiments/homepage-remove-banner-mobile';

// Other Local Dependencies
import {
  verticalComparisonContent,
  byYourSideContent,
  startTodayContent,
  compareFindContent,
  ccBestContent,
  campaignHighlightContent,
  announcementBannerContent,
} from '~/app/lib/front-page-content';
import components from '~/app/lib/nerdai-sc-components';

import styles from './front-page.module.scss';
import AnnouncementBanner from '~/app/components/front-page/announcement-banner';
import SecondaryCampaignModuleContainer from '~/app/components/front-page/secondary-campaign-module/secondary-campaign-module-container';
import SecondaryCampaignModuleContent from '~/app/lib/front-page-content-src/secondary-campaign-module-content';
import CampaignHighlight from '~/app/components/front-page/campaign-highlight/campaign-highlight';
import useIsAuthenticated from '~/app/hooks/isAuthenticated';
import getAuthValues from '~/app/lib/front-page-content-src/getAuthValues';
// @ts-ignore
import initBaseStyles from '@nerdwallet/base-styles/init';
import { initBrowserEvents } from '@nerdwallet/analytics';
import SegmentTracking, {
  SegmentTrackingContext,
} from '~/app/lib/segment/SegmentTracking';
import BounceTracking from '../components/bounce-tracking';
import { useInitializeGtm } from '../lib/initializeGtm';
import { getPageTaxonomy } from '../lib/page-taxonomy';
import { usePathname } from 'next/navigation';

if (typeof window !== 'undefined') {
  initBaseStyles();
  initBrowserEvents();
}

const SHOW_CHATBOT = EXPERIMENTS.chatbotVisibleExperiment.id;
const { VARIANT: CHATBOT_IS_VISIBLE } =
  EXPERIMENTS.chatbotVisibleExperiment.variants;

const HEADLINE = EXPERIMENTS.headlineExperiment.id;
const {
  VARIANT_SIDE: BY_YOUR_SIDE,
  VARIANT_TODAY: START_TODAY,
  VARIANT_COMPARE: COMPARE_FIND,
} = EXPERIMENTS.headlineExperiment.variants;

const FrontPage = () => {
  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(true);
  const isAuthenticated = useIsAuthenticated();

  const client = createClient({
    components,
    schema,
    codesplittingFunction: (asyncImport, exportName) =>
      dynamic(() =>
        asyncImport.then((mod) => ({
          default: mod[exportName],
        })),
      ),
  });
  useInitializeGtm({ pageTaxonomy: getPageTaxonomy(usePathname()) ?? [] });

  return (
    <React.Fragment>
      <BounceTracking />
      <SegmentTracking segmentPageName="homepage">
        <Box marginTop={1}>
          {showAnnouncementBanner && (
            <HomepageRemoveBannerMobile>
              <AnnouncementBanner
                handleOnClose={() => setShowAnnouncementBanner(false)}
                {...getAuthValues(announcementBannerContent, isAuthenticated)}
              />
            </HomepageRemoveBannerMobile>
          )}
          <Experiment testName={HEADLINE}>
            <ExperimentControl>
              <VerticalComparison {...verticalComparisonContent()} />
            </ExperimentControl>
            <ExperimentVariant name={BY_YOUR_SIDE}>
              <VerticalComparisonNoTyping {...byYourSideContent()} />
            </ExperimentVariant>
            <ExperimentVariant name={START_TODAY}>
              <VerticalComparisonNoTyping {...startTodayContent()} />
            </ExperimentVariant>
            <ExperimentVariant name={COMPARE_FIND}>
              <VerticalComparisonNoTyping {...compareFindContent()} />
            </ExperimentVariant>
          </Experiment>
        </Box>
        <SegmentTrackingContext.Consumer>
          {({
            analytics: segmentAnalytics,
            deployableName,
            deployableVersion,
            pageViewId,
          }) => (
            <Experiment testName={SHOW_CHATBOT}>
              <ExperimentVariant name={CHATBOT_IS_VISIBLE}>
                <Box className={styles.contentWrapper}>
                  <StructuredContentProvider client={client}>
                    <AnalyticsContextProvider
                      value={{
                        sectionName: 'chatbot_nerdai',
                        segmentAttributes: {
                          pageViewId,
                          segmentInstance: segmentAnalytics,
                        },
                      }}
                    >
                      <NerdAIChat
                        StructuredContentComponent={StructuredContent}
                        inputPlaceholder="Ask NerdWallet AI..."
                        variant="home"
                        segmentAnalytics={{
                          instance: segmentAnalytics,
                          deployableName,
                          deployableVersion,
                          pageViewId,
                        }}
                      />
                    </AnalyticsContextProvider>
                  </StructuredContentProvider>
                </Box>
              </ExperimentVariant>
            </Experiment>
          )}
        </SegmentTrackingContext.Consumer>
        <CampaignHighlight
          {...campaignHighlightContent}
        />
        <Box marginY={{ mobile: '2', desktop: '3' }}>
          <SecondaryCampaignModuleContainer
            title="More ways to make smarter financial decisions"
            cards={SecondaryCampaignModuleContent}
          />
        </Box>
        <Box marginY={{ mobile: '2', desktop: '3' }}>
          <NewsArticles />
        </Box>
        <CCBest {...ccBestContent} />
      </SegmentTracking>
    </React.Fragment>
  );
};

export default FrontPage;
