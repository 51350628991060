import React from 'react';
import PropTypes from 'prop-types';
import { Button, Title, Text, Link } from '@nerdwallet/currency';
import classNames from 'classnames';
import Image from 'next/image';
import SegmentImpression from '../../segment-impression';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import styles from './campaign-highlight.module.scss';

const CampaignHighlight = ({
  backgroundColor,
  title,
  titleEnd,
  titlePreColor,
  titleEndColor,
  subtitle,
  ctaButtonText,
  ctaLinkUrl,
  visualBackgroundImageDesktopUrl,
  visualBackgroundImageMobileUrl,
  visualBackgroundImageAlt,
  nwplusFullLogo,
  nwplusFullLogoAlt,
  nwplusSmallLogo,
  trkQueryParam,
}) => {
  const titlePreStyle = {
    color: `${titlePreColor}`,
  };

  const titleEndStyle = {
    color: `${titleEndColor}`,
  };

  const { trackSegmentTrackEvent } = useAnalytics();
  const handleCampaignLinkClick = () => {
    trackSegmentTrackEvent(
      'Element Interaction',
      HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign,
    );
  };

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.sub_location,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.location,
      }}
    >
    <div className={styles.campaignHighlight}>
      <div
        className={styles.campaignHighlightCta}
        style={{ backgroundColor }}
      >
        <div className={styles.campaignHighlightNWPlusFullLogo}>
        <Image
          src={nwplusFullLogo}
          alt={nwplusFullLogoAlt}
          width="2000"
          height="200"
          layout="responsive"
          style={{ objectFit: 'cover' }}
        />
        </div>
        <Title size="small" className={styles.campaignHighlightCtaTitle}>
          <span
            className={styles.campaignHighlightCtaTitlePre}
            style={titlePreStyle}
          >
            {title}
          </span>
          <span
            className={styles.campaignHighlightCtaTitleEnd}
            style={titleEndStyle}
          >
            {'\u00A0'}
            {'\u00A0'}
            {titleEnd}
          </span>
        </Title>
        <div className={styles.campaignHighlightCtaText}>
         {subtitle}
        </div>
        <Button
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          primary
          className={styles.campaignHighlightCtaButton}
          onClick={handleCampaignLinkClick}
        >
          {ctaButtonText}
        </Button>
       </div>
       <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerDesktop}
        >
          <Image
            src={visualBackgroundImageDesktopUrl}
            alt={visualBackgroundImageAlt}
            width="590"
            height="418"
            layout="responsive"
            style={{ objectFit: 'cover' }}
            quality={100}
          />
        </Link>
        <Link
          href={`${ctaLinkUrl}?trk=${trkQueryParam}`}
          onClick={handleCampaignLinkClick}
          className={styles.campaignHighlightVisualContainerMobile}
        >
          <div className={styles.campaignHighlightVisualContainerMobile}>
            <Image
              src={visualBackgroundImageMobileUrl}
              alt={visualBackgroundImageAlt}
              width="375"
              height="318"
              layout="responsive"
              style={{ objectFit: 'cover' }}
              quality={100}
              >
            </Image>
          </div>
        </Link>
       </div>
    </SegmentImpression>
  );
};

CampaignHighlight.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  titleEnd: PropTypes.string,
  titlePreColor: PropTypes.string,
  titleEndColor: PropTypes.string,
  subtitle: PropTypes.string,
  ctaButtonText: PropTypes.string,
  ctaLinkUrl: PropTypes.string,
  visualBackgroundImageDekstopUrl: PropTypes.string,
  visualBackgroundImageMobileUrl: PropTypes.string,
  visualBackgroundImageAlt: PropTypes.string,
  nwplusFullLogo: PropTypes.string,
  nwplusFullLogoAlt: PropTypes.string,
  nwplusSmallLogo: PropTypes.string,
  trkQueryParam: PropTypes.string,
};

CampaignHighlight.displayName = 'CampaignHighlight';

export default CampaignHighlight;
