import React from 'react';

import { Accent, Box, Text } from '@nerdwallet/currency';
import Image from '@nerdwallet/react-image';

import { Article } from '~/generated-graphql/generated-types';
import { trackInteraction } from '~/app/lib/analytics/analytics-helpers';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import NewsArticlesAuthor from '../news-articles-author/news-articles-author';
import styles from './news-articles-hero.module.scss';
import useAnalytics from '~/app/lib/segment/useAnalytics';

type NewsArticlesHeroProps = {
  article: Article;
};

const NewsArticlesHero = ({ article }: NewsArticlesHeroProps): JSX.Element => {
  const { trackSegmentTrackEvent } = useAnalytics();

  return (
    <Box display="flex" flexDirection="column">
      <Box marginBottom="3" className={styles.accentWrapper}>
        <Accent>Top Story</Accent>
      </Box>
      <a
        className={styles.articleLink}
        href={article?.link}
        onClick={() => {
          trackInteraction({
            entityName: `breaking_news_feed_featured_${article.id}`,
            sectionName: analyticsBase.section_name,
            action: 'select',
          });
          trackSegmentTrackEvent('Element Interaction', {
            entity_name: `breaking_news_feed_featured_${article.id}`,
            location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
            action: 'select',
          });
        }}
      >
        <Image alt="" src={article?.featuredMedia?.sourceUrl ?? ''} lazy />
        <Box marginTop="3">
          <Text size="large" bold className={styles.articleTitle}>
            {article?.title}
          </Text>
        </Box>
      </a>
      <Box marginTop="2">
        <NewsArticlesAuthor
          avatarUrl={article?.authors?.[0]?.avatarUrls?.[0]?.url}
          authorUrl={article?.authors?.[0]?.link}
          name={article?.authors?.[0]?.name ?? 'NerdWallet'}
        />
      </Box>
    </Box>
  );
};

NewsArticlesHero.displayName = 'NewsArticlesHero';

export default NewsArticlesHero;
