import React from 'react';
// @ts-ignore
import { Impression } from '@nerdwallet/analytics/react/impression';
import {
  Accent,
  Box,
  Column,
  Columns,
  Link,
  LoadingSpinner,
  Title,
} from '@nerdwallet/currency';
import { useFeatureFlag } from '@nerdwallet/features';

import { FEATURE_FLAGS } from '~/app/lib/constants/feature-flags';
import { logGqlError } from '~/app/lib/logger-utils';
import {
  Article,
  NewsArticlesDocument,
  NewsArticlesQuery,
  NewsArticlesQueryVariables,
} from '~/generated-graphql/generated-types';
import {
  HOMEPAGE as analyticsBase,
  HOMEPAGE_SEGMENT_INSTRUMENTATION,
} from '~/app/lib/analytics/analytics-constants';
import NewsArticlesHero from '../news-articles-hero/news-articles-hero';
import NewsArticlesSidebar from '../news-articles-sidebar/news-articles-sidebar';
import styles from './news-articles-container.module.scss';
import SegmentImpression from '~/app/components/segment-impression';
import { useSuspenseQuery } from '@apollo/client';

const NewsArticles = (): JSX.Element | null => {
  const { data: contentEngagementFeatureFlag } = useFeatureFlag({
    featureFlagName: FEATURE_FLAGS.CONTENT_ENGAGEMENT_FEATURE_DATA,
  });

  const featuredArticleId =
    contentEngagementFeatureFlag?.extraData?.featured_news?.article_id;

  const { data = {}, error } = useSuspenseQuery<
    NewsArticlesQuery,
    NewsArticlesQueryVariables
  >(NewsArticlesDocument, {
    variables: {
      identifier: `${featuredArticleId}`,
      exclude: [featuredArticleId],
    },
  });

  if (error) {
    logGqlError(error);
    return null;
  }

  return (
    <SegmentImpression
      eventName="Element Impression"
      eventProps={{
        entity_name: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.entity_name,
        location: HOMEPAGE_SEGMENT_INSTRUMENTATION.breaking_news.location,
      }}
    >
      <Impression
        entityName={analyticsBase.entity_names.breaking_news_feed}
        payload={{ section_name: analyticsBase.section_name }}
      >
        <div className={styles.newsArticlesContainer}>
          <Box marginBottom="3-half">
            <Title size="small">
              Stay up to date with the latest from the Nerds
            </Title>
          </Box>

          <React.Fragment>
            <Columns collapse spacing={{ desktop: '3-half', mobile: 5 }}>
              <Column width="6">
                <NewsArticlesHero article={data.article as Article} />
              </Column>
              <Column width="6">
                <NewsArticlesSidebar
                  articles={data.articles?.items as Array<Article>}
                />
              </Column>
            </Columns>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Link href="/h/news/financial-news">
                <Accent>More News &gt;</Accent>
              </Link>
            </Box>
          </React.Fragment>
        </div>
      </Impression>
    </SegmentImpression>
  );
};

NewsArticles.displayName = 'NewsArticles';

export default NewsArticles;
